<script setup lang="ts">
import DisclaimerPopVue from '../common/DisclaimerPop.vue';
import IcpComp from '../common/IcpComp.vue';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';
const { oeReport } = Haa.util().useOeReport();

import { ref, onMounted, onUnmounted } from 'vue';
import { useParams } from '@/store/useParams';
import { useXz } from '@/hooks/useXz';

enum EventNams {
    Touchstart = 'touchstart',
    Click = 'click'
}

/** 默认使用touchstart触发点击 */
let hotEventName = ref(EventNams.Touchstart);
let notHotEventName = ref(EventNams.Touchstart);

const handleEventName = (type: number) => {
    // 0：全部touch_down触发跳转(默认);
    // 1：热点区域click触发，非热点区域touch_down触发跳转；
    // 2：热点区域touch_down触发，非热点区域click触发跳转;
    // 3：全部click触发跳转；1：热点区域click触发;
    const handleMap = [
        [EventNams.Touchstart, EventNams.Touchstart],
        [EventNams.Click, EventNams.Touchstart],
        [EventNams.Touchstart, EventNams.Click],
        [EventNams.Click, EventNams.Click],
    ]

    const [_hotEventName, _notHotEventName] = handleMap[type];

    _hotEventName && (hotEventName.value = _hotEventName);
    _notHotEventName && (notHotEventName.value = _notHotEventName);
}

const { params } = useParams();
const { active, defaultActive, xzList, handleChange } = useXz();

// 是否已触发跳转 网络不好时多次点击会触发多次上报
const isTriggerJump = ref(false);

const emitChange = defineEmits(['change']);
const handleClick = (index: number, e: any) => {
    let appX, appY;

    if (e) {
        appX = e.touches ? e.touches[0].clientX : e.clientX;
        appY = e.touches ? e.touches[0].clientY : e.clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    let item = xzList[index];

    // 避免网络延迟多次点击重复上报
    if (isTriggerJump.value) return;

    isTriggerJump.value = true;

    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '选择星座:' + item.name, undefined, appX, appY);
    emitChange('change', index);
    handleChange(index);
}
const countdown = ref<number>(+params.skip || 0);
let dpTimer: any = null, strategyTimer: any = null;
if (countdown.value > 0) {
    dpTimer = setInterval(() => {
        countdown.value -= 1;
        if (countdown.value <= 0) {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '倒计时默认星座');
            emitChange('change', defaultActive.value);
            handleChange(defaultActive.value);
            clearInterval(dpTimer);
            dpTimer = null;
        }
    }, 1000);
} else if (countdown.value == 0) {
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'DP控制默认星座');
    emitChange('change', defaultActive.value);
    handleChange(defaultActive.value);
} else {

}

const { strategy } = Haa.util().useStrategy();
let globalClickable = ref<boolean>(false), timeout = ref<number>(-1);
const setGuideParams = () => {
    handleEventName(strategy.guide_used_click);

    if (strategy.guide_click_enable == 1) globalClickable.value = true;
    if (strategy.guide_timeout >= 0) timeout.value = strategy.guide_timeout;
    if (timeout.value > -1) {
        strategyTimer = setTimeout(() => {
            let random = Math.floor(Math.random() * 12);
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '策略控制随机星座');
            emitChange('change', random);
            handleChange(random);
        }, timeout.value * 1000);
    }
}
if (strategy.status != 0) {
    setGuideParams();
} else {
    bus.on('strategy', setGuideParams);
}

const handleTouch = (e: any) => {
    let appX, appY;

    if (e) {
        appX = e.touches ? e.touches[0].clientX : e.clientX;
        appY = e.touches ? e.touches[0].clientY : e.clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    if (!globalClickable.value) return;

    // 避免网络延迟多次点击重复上报
    if (isTriggerJump.value) return;

    isTriggerJump.value = true;


    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch非点击区域', undefined, appX, appY);
    emitChange('change', defaultActive.value);
    handleChange(defaultActive.value);
}
onUnmounted(() => {
    if (dpTimer) {
        clearInterval(dpTimer);
        dpTimer = null;
    }
    if (strategyTimer) {
        clearTimeout(strategyTimer);
        strategyTimer = null;
    }
})

const animateIndex = ref<number>(0);
onMounted(() => {
    setInterval(() => {
        animateIndex.value = (animateIndex.value + 1) % 12;
    }, 1000);
})


/** 31038上报 */
const onCriticalClick = (e: TouchEvent) => {
    let appX, appY;
    if (e) {
        appX = e.touches[0].clientX;
        appY = e.touches[0].clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    oeReport(OeReportType.Action, OeReportSubType.NonCriticalAreaClick, 'Guide', 'NonCriticalAreaClick', undefined, appX, appY);
}
</script>

<template>
    <div class="xz-select-page-wrap" v-on:[notHotEventName]="handleTouch" @touchstart="onCriticalClick">
        <div class="xz-select-page-box">
            <div class="xz-select-title">选择您的星座</div>
            <div class="xz-tips">测试仅供娱乐</div>
            <div v-if="countdown > 0" class="xz-select-desc">--{{ countdown }}s 后默认选择今天星座--</div>
            <div v-else class="xz-select-desc">--默认选择今日星座--</div>
            <DisclaimerPopVue company="山西数字互联科技有限公司"></DisclaimerPopVue>
            <div class="xz-list-box">
                <div
                    v-for="(item, index) in xzList"
                    class="xz-item"
                    :class="{'active': active == index, 'animate': animateIndex == index}"
                    v-on:[hotEventName]="handleClick(animateIndex, $event)"
                    @click.stop
                    @touchstart.stop
                >
                    <img class="avatar" :src="item.avatar" />
                    <div class="xz-name">{{ item.name }}</div>
                    <div class="xz-range">{{ item.time }}</div>
                </div>
            </div>
            <div v-if="timeout > -1" class="fixed-shake">
                <div class="shake-text">摇一摇随机选择</div>
                <img src="@/assets/images/shake.png" />
            </div>
            <icp-comp v-else></icp-comp>
        </div>
        <div class="native-box">
            <wap-native space-key="Guide_native"></wap-native>
        </div>
    </div>
</template>

<style scoped lang="less">
.native-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    opacity: 0;
  }
.xz-select-page-wrap {
    position: fixed;
    top: 0;bottom: 0;
    left: 0;right: 0;
    .xz-select-page-box {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('https://static.zookingsoft.com/zodiac_app/xzcck/icon-bg-xz.png');
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 70px;
        box-sizing: border-box;
    }
    .xz-select-title {
        font-size: 54px;
        line-height: 63px;
        color: #3F2564;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
    }
    .xz-tips {
        font-size: 30px;
        line-height: 40px;
        color: #3F2564;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
    }
    .xz-select-desc {
        font-size: 24px;
        line-height: 34px;
        color: #3F2564;
        opacity: 0.6;
        text-align: center;
    }
    .xz-list-box {
        .xz-item {
            width: 25%;
            display: inline-block;
            text-align: center;
            margin-bottom: 26px;
            opacity: 0.3;
            .avatar {
                width: 120px;
                height: 120px;
                margin-bottom: 12px;
            }
            .xz-name {
                font-size: 28px;
                line-height: 32px;
                color: #3F2564;
            }
            .xz-range {
                font-size: 20px;
                line-height: 28px;
                color: #3F2564;
                opacity: 0.6;
            }
            &.active {
                opacity: 1;
            }
        }
        .animate {
            animation-name: skip;
            animation-duration: 1000ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
    .fixed-icp {
        position: absolute;
        width: 100%;
        bottom: 0;left: 0;
    }
    .fixed-shake {
        width: 100%;
        // height: 400px;
        // position: absolute;
        bottom: 0;left: 0;
        text-align: center;
        // background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1));
        img {
            width: 200px;
        }
        .shake-text {
            color: rgba(0, 0, 0, 0.6);
            font-size: 26px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}
@keyframes skip {
    0% {
        transform: translateY(0);
        opacity: 0.3;
    }
    50% {
        transform: translateY(-30px);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 0.3;
    }
}
</style>