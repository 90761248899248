<script setup lang="ts">
import MainTab from "@/components/MainTab.vue";

import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import { Haa } from '@haa/fwk-h5/lib/index';
import { ref } from "vue";

Haa.util().useLifeCycle();

const showBottomNative = ref(false);

const router = useRouter();
const tabList = reactive([
    { icon: require('@/assets/images/tab_1.png'), text: '数联观星', path: '/home' },
    { icon: require('@/assets/images/tab_2.png'), text: '星座解读', path: '/analysis' },
    { icon: require('@/assets/images/tab_3.png'), text: '答案之书', path: '/answer' },
    { icon: require('@/assets/images/tab_4.png'), text: '测一测', path: '/topic' },
])
const handleTabChange = function (value: number) {
    let tab = tabList[value];
    router.replace({ path: tab.path });

    showBottomNative.value = false;
}


const handleScroll = (e: any) => {
    let scrollTop = e.target.scrollTop;
    if (scrollTop > 500) {
        showBottomNative.value = true;
    }
}

</script>

<template>
    <div class="home-view-wrapper">
        <div class="main-title">
            <img src="@/assets/images/icon.png" />
            <span>数联观星</span>
        </div>


        <div class="page-box" v-slide @scroll="handleScroll">
            <main-tab @change="handleTabChange"></main-tab>

            <router-view v-slot="{ Component }">
                <component :is="Component" :show-bottom-native="showBottomNative"></component>
            </router-view>
        </div>

        <wap-banner space-key="Home_banner"></wap-banner>
    </div>
</template>

<style scoped lang="less">
.home-view-wrapper {
    height: 100vh;
    background-image: url('@/assets/images/main_bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 0 30px;
    display: flex;
    flex-direction: column;

    .main-title {
        display: flex;
        align-items: center;
        font-size: 46px;
        color: #3F2564;
        padding: 20px 0;
        img {
            width: 60px;
            height: 60px;
            margin-right: 6px;
        }
    }

    .page-box {
        flex: 1;
        overflow-y: auto;
    }
}
</style>
