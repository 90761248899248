<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const active = ref<number>(0);
const route = useRoute();
watch(
    () => route.path,
    () => {
        tabList.forEach((item, index) => {
            if (item.path == route.path) active.value = index;
        })
    }
);
const emitChange = defineEmits(['change']);
const handleTabChange = function (index: number) {
    active.value = index;
    oeReport(OeReportType.Action, OeReportSubType.TabClick, tabList[active.value].text);
    emitChange('change', active.value);
}

const tabList = reactive([
    { icon: require('@/assets/images/tab_1.png'), text: '数联观星', path: '/home' },
    { icon: require('@/assets/images/tab_2.png'), text: '星座解读', path: '/analysis' },
    { icon: require('@/assets/images/tab_3.png'), text: '答案之书', path: '/answer' },
    { icon: require('@/assets/images/tab_4.png'), text: '测一测', path: '/topic' },
])
tabList.forEach((item, index) => {
    if (item.path == route.path) active.value = index;
})
</script>

<template>
    <div class="main-tab-wrapper">
        <div class="main-tab-box">
            <div v-for="(item, index) in tabList" class="tab-item" :class="{'active': active == index}" @click="handleTabChange(index)">
                <img :src="item.icon"/>
                <span>{{ item.text }}</span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.main-tab-wrapper {
    padding: 0 0 30px;

    .main-tab-box {
        display: flex;
        justify-content: space-between;
        .tab-item {
            width: 150px;
            background-color: #fff;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #666;
            padding: 15px 0;
            opacity: 0.6;
            img {
                width: 90px;
                height: 90px;
                margin-bottom: 12px;
            }
            &.active {
                opacity: 1;
            }
        }
    }
}
</style>